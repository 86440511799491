

.all-skills{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
}

.header-skills{
    text-align: center;
    margin-bottom: 50px;
    color: white;
}

.languages, .frontend, .backend, .database{
    display: flex;
    flex-direction: column;
   align-items: center;
}

.titlo{
    margin-bottom: 25px !important;
    color: white;
}

@media screen and (max-width: 600px) {
    #skills {
        text-align: center;
        
        
    }
    .all-skills{
       
        flex-wrap: wrap;
        justify-content: center;
        margin: 30px;
    gap: 65px;
    }
   
  }



  .icon-re{
      size: 80px;
  }

  .download-butt{
      text-align: end;
  }


  #skills{
    padding-left: 35px;
    padding-top: 75px;
    padding-bottom: 66px;
    padding-right: 35px;
}
  